export default {
  "header-cell": "cDj",
  "checkbox-cell": "cDW",
  "title-col": "cDu",
  "internal-note-col": "cVS",
  "unit-col": "cVc",
  "price-col": "cVq",
  "header-content": "cVZ caption-bold",
  "active": "cVR",
  "empty": "cVQ",
  "align-checkbox": "cVe"
};
