export default {
  "wrapper": "cJB",
  "cashflow-status": "cJE",
  "status": "cJd",
  "estimated": "cJI",
  "vat-due-amount": "cJt title-3",
  "vat-tooltip": "cJM",
  "icon-info": "cJP",
  "charts-wrapper": "cJl",
  "chart-error": "cJX",
  "chart-controls": "cJC",
  "amount-loading": "cJk"
};
