export default {
  "sidebar-section": "ceh body-2",
  "sidebar-section-header": "cef body-1 mb-4",
  "providers-logos": "ceK",
  "risks": "ceG",
  "tag": "cer tag",
  "external-link-icon": "ceb",
  "disclaimer": "cey",
  "divider": "ceU"
};
