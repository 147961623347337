export default {
  "donut-chart-container": "cCq",
  "donut-chart-wrapper": "cCZ",
  "chart": "cCR",
  "donut-chart-legend-container": "cCQ caption",
  "donut-chart-legend-item-empty": "cCe",
  "donut-chart-legend-bullet-empty": "cCB",
  "donut-chart-legend-bullet": "cCE",
  "donut-chart-legend-text-empty": "cCd",
  "donut-chart-legend-text": "cCI",
  "donut-chart-custom-label-uncategorized": "cCt",
  "custom-label-uncategorized-button": "cCM body-1",
  "donut-chart-legend": "cCP",
  "donut-chart-legend-item": "cCl",
  "donut-chart-legend-other-item": "cCX",
  "donut-chart-legend-uncategorized-item": "cCC",
  "donut-chart-legend-text-name": "cCk",
  "tooltip": "cCJ"
};
