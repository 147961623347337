export default {
  "container": "cBe",
  "header": "cBB",
  "content": "cBE",
  "info-list": "cBd",
  "info-label": "cBI body-2",
  "info-value": "cBt body-2",
  "provider": "cBM",
  "policy-container": "cBP",
  "policy-label": "cBl body-2",
  "policy-number": "cBX body-2",
  "copy-to-clipboard": "cBC",
  "button": "cBk btn btn--primary btn--stretch btn--large"
};
