export default {
  "name-wrapper": "cgc",
  "preset-name": "cgq",
  "invoices-entry-period-container": "cgZ",
  "transactions-entry-period-container": "cgR",
  "period-wrapper": "cgQ",
  "period-btn": "cge",
  "cancel-placeholder": "cgB",
  "period-cancel-btn": "cgE",
  "transactions-entry-container": "cgd",
  "invoices-entry-container": "cgI",
  "dropdown": "cgt",
  "dropdown-opened": "cgM",
  "dropdown-closed": "cgP"
};
