export default {
  "account-container": "csf",
  "badge": "csK",
  "subtitle": "csG title-4",
  "accounts-filter": "csr",
  "account-filter-label": "csb",
  "status": "csy",
  "account-info": "csU",
  "balance": "csj",
  "revealed-balance": "csW",
  "hidden-balance": "csu",
  "mr-12": "cFS",
  "disabled-selector": "cFc"
};
