export default {
  "fieldset": "cEx",
  "numbering-format": "cEw",
  "numbering-fields": "cEN",
  "input-settings": "cEv",
  "label-tooltip": "cEo",
  "tooltip-icon": "cEn",
  "next-invoice-number": "cEi",
  "spacing": "cEA"
};
