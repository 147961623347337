export default {
  "body": "cMr",
  "members": "cMb",
  "members-results": "cMy",
  "members-list": "cMU",
  "members-title": "cMj caption-bold",
  "member": "cMW",
  "empty-illustration": "cMu",
  "empty-title": "cPS title-3",
  "empty-description": "cPc body-2",
  "invitable-members": "cPq",
  "details": "cPZ",
  "pagination-footer": "cPR"
};
