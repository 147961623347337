export default {
  "bucket-item": "cQp",
  "button": "cQx",
  "content": "cQw",
  "bucket-icon": "cQN",
  "bucket-title-container": "cQv",
  "bucket-new-badge": "cQo",
  "bucket-title": "cQn",
  "arrow-icon": "cQi"
};
