export default {
  "details-box": "cln",
  "picto": "cli",
  "picto-status": "clA",
  "ghost": "clY",
  "primary-info": "clh",
  "date": "clf",
  "account-infobox": "clK",
  "declined-disclaimer": "clG",
  "nrc-download": "clr"
};
