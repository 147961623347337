export default {
  "row": "clb",
  "terminated": "cly",
  "status-avatar": "clU",
  "amount": "clj body-1",
  "item-info": "clW",
  "date-cell": "clu",
  "cell": "cXS body-2",
  "status-icon": "cXc",
  "amount-cell": "cXq",
  "item-name-cell": "cXZ",
  "item-name-container": "cXR"
};
