export default {
  "custom-period-wrapper": "cCr",
  "fadeIn": "cCb",
  "custom-period-description": "cCy body-2",
  "header": "cCU",
  "arrow-left": "cCj",
  "body": "cCW",
  "period-wrapper": "cCu",
  "back-button": "ckS",
  "footer": "ckc"
};
