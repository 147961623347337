export default {
  "wrapper": "cBa",
  "mobile-mode": "cBH",
  "logo": "cBO",
  "address-form": "cBm",
  "button-container": "cBp",
  "address-form-header": "cBx",
  "address-form-content": "cBw",
  "address-form-address-search": "cBN",
  "ember-basic-dropdown": "cBv",
  "address-form-footer": "cBo",
  "submit-cta": "cBn",
  "form": "cBi",
  "city-section": "cBA",
  "title": "cBY",
  "mobile-title": "cBh"
};
