export default {
  "file-uploader": "cVH",
  "file-uploader-content": "cVO",
  "file-uploader-dropzone": "cVm",
  "fixedSize": "cVp",
  "two-column": "cVx",
  "file-uploader-label": "cVw body-1",
  "required-chip": "cVN",
  "animated-container": "cVv",
  "toggle-button": "cVo",
  "toggle-button-icon": "cVn"
};
