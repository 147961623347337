/* import __COLOCATED_TEMPLATE__ from './item-selector.hbs'; */
import { assert } from '@ember/debug';
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { modifier } from 'ember-modifier';
import window from 'ember-window-mock';

// @ts-expect-error
import { TRANSITION_SIDEBAR_TIME } from 'qonto/constants/sidebar';

interface ItemSelectorSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ItemSelectorComponent extends Component<ItemSelectorSignature> {
  @service declare intl: Services['intl'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @tracked _selectedItemIds = [];
  @tracked _highlightedItemId = null;
  autoHighlightIds = [];

  // This will hold the items from the react component
  // @ts-expect-error
  @tracked _loadedItems;
  // @ts-expect-error
  @tracked _loadedMeta;

  /**
   * Returns the items either from the arguments or the ones loaded by a child (react) component
   */
  get items() {
    // @ts-expect-error
    return this._loadedItems ?? this.args.items;
  }

  /**
   * Returns the meta either from the arguments or the ones loaded by a child (react) component
   */
  get meta() {
    // @ts-expect-error
    return this._loadedMeta ?? this.args.meta;
  }

  get selectedItemIds() {
    // @ts-expect-error
    return this.args.selectedItemIds ?? this._selectedItemIds;
  }

  get selectedItems() {
    // @ts-expect-error
    return this.items?.filter(item => this.selectedItemIds.includes(item.id));
  }

  get allSelected() {
    return (
      // @ts-expect-error
      this.selectedItems.length === this.args.maxSelectedItems ||
      (this.selectedItems.length === this.items?.length && this.items.length > 0)
    );
  }

  get someSelected() {
    return this.selectedItems.length > 0 && !this.allSelected;
  }

  get hasFeaturedItems() {
    return this.someSelected || this.allSelected || Boolean(this.highlightedItem);
  }

  get highlightedItem() {
    // @ts-expect-error
    let { highlightedItemId, items } = this.args;

    if (highlightedItemId) {
      // @ts-expect-error
      return items?.find(item => item.id === highlightedItemId);
    }
    // @ts-expect-error
    return items?.find(item => item.id === this._highlightedItemId);
  }

  @action
  // @ts-expect-error
  toggleSelectedItem(itemId, clearHighlight = true) {
    if (clearHighlight) {
      this.clearHighlight();
    }

    let newItems = [];
    let isItemSelected = false;
    if (this.selectedItemIds.includes(itemId)) {
      // @ts-expect-error
      newItems = this.selectedItemIds.filter(id => itemId !== id);
    } else {
      isItemSelected = true;
      // @ts-expect-error
      newItems = [...this.selectedItemIds, itemId].slice(0, this.args.maxSelectedItems);
    }
    // @ts-expect-error
    if (this.selectedItemIds.length >= this.args.maxSelectedItems) {
      this.toastFlashMessages.toastInfo(
        this.intl.t('requests.reimbursements.bulk.toasts.error.maximum-selection', {
          // @ts-expect-error
          count: this.args.maxSelectedItems,
        })
      );
    }

    // @ts-expect-error
    if (this.args.didSelectItems) {
      // @ts-expect-error
      this.args.didSelectItems(newItems, { isOneItemSelected: isItemSelected });
    } else {
      this._selectedItemIds = newItems;
    }
  }

  @action
  toggleAllSelected(clearHighlight = true) {
    if (clearHighlight) {
      this.clearHighlight();
    }

    // @ts-expect-error
    let itemIds = this.items.map(item => item.id);
    let newItems = [];
    if (this.allSelected || this.someSelected) {
      newItems = [];
      // @ts-expect-error
    } else if (this.args.maxSelectedItems) {
      // @ts-expect-error
      newItems = itemIds.slice(0, this.args.maxSelectedItems);
    } else {
      newItems = itemIds;
    }

    // @ts-expect-error
    if (itemIds.length > this.args.maxSelectedItems) {
      this.toastFlashMessages.toastInfo(
        this.intl.t('requests.reimbursements.bulk.toasts.error.maximum-selection', {
          // @ts-expect-error
          count: this.args.maxSelectedItems,
        })
      );
    }

    // @ts-expect-error
    if (this.args.didSelectItems) {
      // @ts-expect-error
      this.args.didSelectItems(newItems, { isAllItemsSelected: newItems.length ? true : false });
    } else {
      this._selectedItemIds = newItems;
    }
  }

  @action
  // @ts-expect-error
  didLoadItems({ transactions, meta }) {
    this._loadedItems = transactions;
    this._loadedMeta = {
      total_count: meta.totalCount,
      current_page: meta.currentPage,
      next_page: meta.nextPage,
      per_page: meta.perPage,
      prev_page: meta.prevPage,
      total_pages: meta.totalPages,
    };
  }

  @action
  // @ts-expect-error
  highlightItem(itemId) {
    // @ts-expect-error
    let { didHighlightItem, afterHighlight } = this.args;
    if (this.someSelected || this.allSelected) {
      this.toggleSelectedItem(itemId);
      this._highlightedItemId = null;
    } else {
      if (didHighlightItem) {
        didHighlightItem(itemId);
      } else {
        this._highlightedItemId = itemId;
      }

      afterHighlight?.(this.highlightedItem);
    }
  }

  @action
  clearHighlight() {
    // @ts-expect-error
    if (this.args.clearHighlight) {
      // @ts-expect-error
      this.args.clearHighlight();
      // @ts-expect-error
    } else if (this.args.didHighlightItem) {
      // @ts-expect-error
      this.args.didHighlightItem(null);
    } else {
      this._highlightedItemId = null;
    }

    if (this._highlightedItemId) {
      window.dispatchEvent(
        new CustomEvent('sidebar:close', { detail: { id: this._highlightedItemId } })
      );
    }
  }

  @action
  clearSelected() {
    // @ts-expect-error
    if (this.args.didSelectItems) {
      // @ts-expect-error
      this.args.didSelectItems(null);
    } else {
      this._selectedItemIds = [];
    }
  }

  @action
  clearFeaturedItems(clearHighlight = true) {
    if (clearHighlight) {
      this.clearHighlight();
    }
    this.clearSelected();
  }

  @action
  // @ts-expect-error
  focusSidebar(idToFocus) {
    // Waiting for the animation to finish to focus the close button
    setTimeout(
      () => {
        document.getElementById(idToFocus)?.focus();
      },
      this.highlightedItem ? 0 : TRANSITION_SIDEBAR_TIME
    );
  }

  // @ts-expect-error
  onItemKeydown = modifier((element, [itemId], { autoHighlight = true }) => {
    assert('itemId argument is required in ItemSelector.onItemKeydown modifier', itemId);
    // @ts-expect-error
    element.dataset.itemId = itemId;
    if (autoHighlight) {
      // @ts-expect-error
      this.autoHighlightIds.push(itemId);
    }
    element.addEventListener('keydown', this.highlightWithKeyboard);
    return () => {
      this.autoHighlightIds = this.autoHighlightIds.filter(id => id !== itemId);
      element.removeEventListener('keydown', this.highlightWithKeyboard);
    };
  });

  @action
  // @ts-expect-error
  highlightWithKeyboard(event) {
    let itemId = event.currentTarget.dataset?.itemId;

    if (!itemId) {
      // @ts-expect-error
      return this.highlightWithKeyboardLegacy(event);
    }

    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      // @ts-expect-error
      if (this.args.sidebarIdToFocus) {
        // @ts-expect-error
        this.focusSidebar(this.args.sidebarIdToFocus);
      }
      this.highlightItem(itemId);
      return;
    }

    if (this.selectedItemIds.length || !this.highlightedItem) return;

    // @ts-expect-error
    let item = this.items.find(item => String(item.id) === itemId);
    let index = this.items.indexOf(item);
    let newIndex;

    if (event.key === 'ArrowUp' || event.key === 'k') {
      let isFirst = index === 0;
      if (isFirst) {
        return;
      }
      newIndex = index - 1;
    } else if (event.key === 'ArrowDown' || event.key === 'j') {
      let isLast = index === this.items.length - 1;
      if (isLast) {
        return;
      }
      newIndex = index + 1;
    } else if (event.key === 'Escape') {
      this.clearHighlight();
      return;
    } else {
      return;
    }

    let newItem = this.items[newIndex];
    event.currentTarget.parentNode.querySelector(`[data-item-id="${newItem.id}"]`)?.focus();

    // @ts-expect-error
    if (this.autoHighlightIds.includes(newItem.id)) {
      this.highlightItem(newItem.id);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ItemSelector: typeof ItemSelectorComponent;
  }
}
