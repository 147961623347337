export default {
  "company-logo": "cPy",
  "list-item": "cPU",
  "link": "cPj",
  "title-wrapper": "cPW",
  "title": "cPu body-2",
  "subtitle": "clS caption",
  "truncated-text": "clc",
  "image-placeholder": "clq"
};
