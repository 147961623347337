export default {
  "item-status": "cJu",
  "item-date": "csS",
  "item-amount": "csc body-1",
  "item-amount-credit": "csq",
  "row": "csZ",
  "icon": "csR",
  "disabled": "csQ",
  "icon-container": "cse",
  "text-container": "csB",
  "text-row": "csE",
  "item-details": "csd",
  "counterparty-name": "csI",
  "column": "cst",
  "item-amount-disabled": "csM",
  "attachment-tooltip": "csP",
  "bank-account-avatar": "csl"
};
