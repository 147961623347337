export default {
  "header": "cFD",
  "top-section": "cFV",
  "close": "cFL btn btn--icon-only btn--tertiary",
  "container": "cFT",
  "picto": "cFz",
  "details": "cFa",
  "expiration-date": "cFH",
  "payment-method": "cFO"
};
