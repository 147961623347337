export default {
  "wrapper": "cJw",
  "header": "cJN",
  "fadeIn": "cJv",
  "header-content": "cJo",
  "with-cta": "cJn",
  "header-title": "cJi title-4",
  "advanced-filters-trigger": "cJA",
  "filters-button": "cJY",
  "filters-button-upsell": "cJh",
  "filters-upsell-item": "cJf",
  "filters-badge": "cJK",
  "chart-container": "cJG",
  "chart-legend-container": "cJr mb-24",
  "chart-watermark-container": "cJb",
  "chart-watermark": "cJy",
  "placeholder-title": "cJU",
  "placeholder-subtitle": "cJj",
  "advanced-filters": "cJW"
};
