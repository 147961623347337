export default {
  "y-axis-placeholder": "cXY",
  "y-axis-navigable": "cXh",
  "y-axis-tick": "cXf",
  "x-axis-placeholder": "cXK",
  "x-axis-navigable": "cXG",
  "x-axis-tick-wrapper": "cXr",
  "x-axis-tick": "cXb",
  "chart-loading-renderer": "cXy"
};
