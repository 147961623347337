export default {
  "mandate-list-item": "ctm",
  "selected": "ctp",
  "status-avatar": "ctx",
  "infos": "ctw",
  "info-title": "ctN body-1",
  "info-title-suspended": "ctv",
  "info-title-status-suspended": "cto",
  "info-mandate-count": "ctn body-2"
};
