export default {
  "item": "cMa",
  "selected": "cMH",
  "hover-revised": "cMO",
  "asset-container": "cMm",
  "asset": "cMp",
  "revoked-icon": "cMx",
  "pending-icon": "cMw",
  "revoked": "cMN",
  "revised-revoke": "cMv",
  "pending": "cMo",
  "revised-pending": "cMn",
  "invitable": "cMi",
  "info": "cMA",
  "name": "cMY",
  "email": "cMh",
  "actions": "cMf",
  "invitable-member-delete": "cMK",
  "invitable-member-invite": "cMG"
};
