export default {
  "container": "cdQ",
  "form-container": "cde",
  "title": "cdB",
  "title-border": "cdE",
  "tabs-container": "cdd",
  "preview-container": "cdI",
  "email-preview-container": "cdt",
  "close-button": "cdM btn btn--icon-only btn--tertiary btn--large"
};
