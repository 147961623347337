export default {
  "header-cell": "cIc",
  "empty": "cIq",
  "header-content": "cIZ caption-bold",
  "active": "cIR",
  "align-right": "cIQ",
  "first-col": "cIe",
  "mid-col": "cIB",
  "status-col": "cIE"
};
