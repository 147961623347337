export default {
  "fullscreen-modal": "cDT",
  "creation-form-modal": "cDz",
  "links-container": "cDa",
  "link-field": "cDH",
  "link": "cDO",
  "add-link": "cDm",
  "remove-link": "cDp",
  "flex-row": "cDx",
  "flex-item": "cDw",
  "unit-trigger": "cDN",
  "dynamic-placeholders": "cDv"
};
