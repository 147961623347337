export default {
  "section-item": "ckZ",
  "sm": "ckR",
  "xs": "ckQ",
  "section-item-label": "cke body-2",
  "section-item-value": "ckB body-2",
  "positive": "ckE",
  "negative": "ckd",
  "hidden": "ckI",
  "section-item-caption": "ckt caption"
};
