export default {
  "row": "cFn",
  "cell": "cFi body-1",
  "row-cancelled": "cFA",
  "cell-amount": "cFY",
  "empty": "cFh",
  "align-right": "cFf",
  "debitor-container": "cFK",
  "debitor-info": "cFG",
  "debitor-name": "cFr body-1",
  "with-sidebar": "cFb",
  "method-col": "cFy",
  "resource-number": "cFU caption",
  "resource-icon": "cFj",
  "avatar": "cFW"
};
