export default {
  "integration-item": "cQG",
  "icon-container": "cQr",
  "content": "cQb",
  "title-container": "cQy",
  "new-badge": "cQU",
  "title": "cQj",
  "description": "cQW",
  "no-attributes": "cQu",
  "connect-cta": "ceS"
};
