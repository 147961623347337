export default {
  "option": "cgv",
  "account-description": "cgo",
  "account-identification": "cgn",
  "account-avatar": "cgi",
  "account-name": "cgA body-2",
  "account-balance": "cgY body-2",
  "external-accounts-disclaimer": "cgh",
  "status": "cgf body-2",
  "failed-connection": "cgK"
};
