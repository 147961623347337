export default {
  "container": "cIV",
  "mapped": "cIL",
  "header": "cIT",
  "title": "cIz caption",
  "select-option-label": "cIa",
  "content": "cIH",
  "item": "cIO body-2",
  "empty": "cIm"
};
