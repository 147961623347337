export default {
  "sidebar": "cdv",
  "top-section": "cdo",
  "title": "cdn",
  "primary-action": "cdi",
  "edit-icon": "cdA",
  "close-icon": "cdY",
  "box-wrapper": "cdh",
  "bottom-action": "cdf",
  "related-invoices": "cdK",
  "related-invoices-title": "cdG mb-16 caption-bold",
  "box": "cdr",
  "box-element": "cdb",
  "cancel-text": "cdy",
  "box-header": "cdU",
  "row": "cdj",
  "greyed-out": "cdW",
  "struck-through": "cdu",
  "share-mandate-link": "cIS"
};
