export default {
  "card": "cXk",
  "header": "cXJ",
  "avatar": "cXs",
  "title": "cXF",
  "subtitle": "cXg",
  "label": "cXD",
  "item": "cXV body-2",
  "header-icon": "cXL",
  "dropdown-menu": "cXT",
  "menu-item": "cXz body-2",
  "delete": "cXa"
};
