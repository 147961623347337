export default {
  "notifications-settings": "clQ",
  "opened": "cle",
  "icon": "clB",
  "header": "clE",
  "header-toggle": "cld",
  "avatar-image": "clI",
  "title": "clt",
  "icon--right": "clM",
  "toggle-container": "clP",
  "toggle": "cll"
};
