export default {
  "widgets-container": "ckW",
  "subtitle-container": "cku",
  "indicator-container": "cJS",
  "transactions-container": "cJc",
  "indicator": "cJq",
  "cashflow-container": "cJZ",
  "cashflow": "cJR",
  "donut-container": "cJQ",
  "donut": "cJe"
};
