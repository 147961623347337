export default {
  "invitations-password-form": "cEI",
  "mobile-mode": "cEt",
  "logo": "cEM",
  "header": "cEP",
  "controls": "cEl",
  "footer": "cEX",
  "submit-cta": "cEC",
  "subtitle": "cEk"
};
