export default {
  "bullet": "ckF",
  "slice-0": "ckg",
  "slice-1": "ckD",
  "slice-2": "ckV",
  "slice-3": "ckL",
  "slice-4": "ckT",
  "slice-5": "ckz",
  "slice-6": "cka",
  "slice-other": "ckH",
  "slice-uncategorized": "ckO",
  "label": "ckm",
  "ellipsis": "ckp",
  "rest": "ckx",
  "previous-date-range": "ckw caption"
};
