export default {
  "header": "cda",
  "center": "cdH",
  "header-placeholder": "cdO",
  "placeholder": "cdm",
  "name-block": "cdp",
  "block": "cdx",
  "detail-block": "cdw",
  "border-top": "cdN"
};
