export default {
  "wrapper": "csX",
  "header": "csC",
  "fadeIn": "csk",
  "header-title": "csJ title-4",
  "transactions-container": "css",
  "placeholder-title": "csF",
  "transaction-item-list-container": "csg",
  "transaction-item-list": "csD",
  "item": "csV"
};
