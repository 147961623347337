export default {
  "wrapper": "cew",
  "content": "ceN",
  "back": "cev btn btn--tertiary",
  "heading": "ceo",
  "heading-content": "cen",
  "logo": "cei",
  "description": "ceA body-2",
  "cta": "ceY"
};
