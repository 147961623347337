export default {
  "row": "cVd",
  "cell": "cVI",
  "title": "cVt",
  "internal-note": "cVM",
  "unit": "cVP",
  "price": "cVl",
  "quick-actions": "cVX",
  "missing-details-container": "cVC",
  "missing-details-label": "cVk body-2",
  "warning-icon": "cVJ",
  "empty": "cVs",
  "checkbox": "cVF",
  "align-checkbox": "cVg"
};
