export default {
  "navigation-dropdown": "cPO",
  "expanded": "cPm",
  "trigger": "cPp",
  "trigger-title-icon": "cPx",
  "collapsed": "cPw",
  "responsive": "cPN",
  "logo": "cPv",
  "active": "cPo",
  "trigger-title": "cPn",
  "content": "cPi",
  "content-container": "cPA",
  "dropdown-icon": "cPY",
  "link": "cPh"
};
