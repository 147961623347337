export default {
  "card": "cXH",
  "header": "cXO",
  "avatar": "cXm",
  "title": "cXp",
  "subtitle": "cXx",
  "label": "cXw",
  "item": "cXN body-2",
  "header-icon": "cXv",
  "mt-8": "cXo"
};
