export default {
  "card": "cIW",
  "focus": "cIu",
  "title-format": "ctS",
  "header": "ctc",
  "headings": "ctq",
  "title": "ctZ title-4",
  "main": "ctR title-3",
  "footer": "ctQ"
};
