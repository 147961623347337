export default {
  "members-loading": "cPM",
  "members-loading--ff": "cPP",
  "header": "cPl",
  "header-search-bar": "cPX",
  "body": "cPC",
  "members": "cPk",
  "placeholder-container": "cPJ",
  "icon": "cPs",
  "details": "cPF",
  "member-loading": "cPg",
  "member-loading--no-ff": "cPD",
  "body-members": "cPV",
  "body-details": "cPL"
};
